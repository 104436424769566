
import { defineComponent } from "vue";

export default defineComponent({
  name: "Popup",
  props: {
    title: String,
    show: Boolean,
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
});
