<template>
  <div class="album-card">
    <div class="album-card__content">
      <div class="album-card__content-title">我们一周年啦！</div>
      <div class="album-card__content-caption">
        <span>来自牛牛</span>
      </div>
      <div class="album-card__content-text">
        牛爱猪啊～ 猪爱牛～ 牛牛猪猪甜蜜蜜 <br />
        猪爱牛啊～ 牛爱猪～ 猪猪牛牛甜蜜蜜 <br />
        铛铛铛铛！牛牛和猪猪在一起365天啦。我的可爱/懂事/听话的乖乖猪，这是我们一起走过的第一个一年四季。
        过去一年，猪猪牛牛去过了 <span>杭州</span><span>上海</span>
        <span>长沙</span><span>重庆</span><span>成都</span>。
        <br />猪猪牛牛一起去了迪斯尼 <br />猪猪牛牛一起做了伴郎
        <br />猪猪牛牛开始了储蓄计划 <br />猪猪牛牛的朋友们都慢慢知道了彼此
        <br />猪猪牛牛住到了新的地方，有了两个人的小空间 ......
        <br />
        「无论开心还是不开心，就让我一直陪着你吧」
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.album-card {
  height: calc(100vh - 174px);
  overflow: hidden;
  padding-bottom: 60px;
  background: linear-gradient(
    180deg,
    rgba(141, 27, 255, 0.19) 0%,
    rgba(0, 203, 206, 0.325) 100%
  );
  background-image: url("https://freebiesui.com/wp-content/uploads/2020/12/Plant-Shop-E-Commerce-App-figma.jpg");
  background-repeat: repeat;
  background-size: contain;
  &__content {
    height: calc(100vh - 490px);
    margin-top: 490px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(90px);
    border-radius: 0px 190px 0px 0px;
    padding: 80px 60px 0 60px;
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 46px;
      line-height: 140%;
      color: #17002f;
    }
    &-caption {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 40px 0;
      span {
        background: #fff3d0;
        border-radius: 8px;
        padding: 12px 28px;
      }
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 195.5%;
      color: rgba(0, 0, 0, 0.68);
      span {
        margin: 0 4px;
        text-decoration: underline #32557f dashed;
      }
    }
  }
}
</style>
